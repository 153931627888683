.App {
  text-align: left;
}
.form{
  display: inline-block;
  justify-content: left;

}
.form input{
  margin: 10px;
}

.form textarea{
  margin: 10px;
}

.c1.cm-editor.cm-focused {
  outline: 0px !important;
}